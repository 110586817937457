<template>
  <div class="skeleton-list">
    <p class="header-1" v-if="title">
      {{ title }}
    </p>
    <el-skeleton :animated="animated" :count="count">
      <template slot="template">
        <div class="skeleton-list__wrapper">
          <el-skeleton-item variant="rect" />
          <div class="skeleton-list__description">
            <el-skeleton-item variant="h1" />
            <el-skeleton-item variant="p" />
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    count: {
      type: Number,
      required: false,
      default: 3
    }
  },
}
</script>

<style lang="scss">
  .skeleton-list {
    margin-top: 24px;
    
    .el-skeleton {
      width: 100%;
      margin-bottom: 24px;
      background: white;
      border-radius: 4px;
      border-top: 1px solid #eeeeee;

      &__rect {
        height: 20px; 
        width: 60px; 
        margin-right: 22px;
      }

      &__h1 {
        height: 20px; 
        margin-bottom: 4px; 
        width: 400px;
      }
      
      &__p {
        height: 20px;
        width: 200px
      }
    }

    &__wrapper {
      display: flex; 
      align-items: center; 
      padding: 24px 16px;
    }
    
    &__description {
      display: flex;
      flex-direction: column;
    }
  }
</style>