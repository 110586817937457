<template>
  <div class="skeleton-credit-card">
    <p class="header-1" v-if="title">
      {{ title }}
    </p>
    <el-skeleton :animated="true" :count="3">
      <template slot="template">
        <div class="skeleton-credit-card__wrapper">
          <div class="skeleton-credit-card__wrapper__part-1">
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="h1" />
          </div>
          <div class="skeleton-credit-card__wrapper__part-2">
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="p" />
          </div>
          <div class="skeleton-credit-card__wrapper__part-3">
            <el-skeleton-item variant="p" />
            <el-skeleton-item variant="h1" />
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    count: {
      type: Number,
      required: false,
      default: 3
    }
  },
}
</script>

<style lang="scss">
  .skeleton-credit-card {
    margin-bottom: 24px;
    
    .el-skeleton {
      display: flex;
      width: 100%;

      > div:not(:last-of-type) {
        margin-right: 24px;
      }
    }

    &__wrapper {
      height: 182px;
      background-color: #fff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      flex: 1;
      padding: 32px 24px;

      &__part-1 {
        display: flex;

        .el-skeleton__p {
          width: 70px;
          margin-right: auto;
        }
        .el-skeleton__h1 {
          height: 35px;
          width: 80px;
        }
      }
      &__part-2 {
        display: flex;
        flex-direction: column;

        .el-skeleton__p {
          &:nth-child(1) {
            width: 100px;
            margin-bottom: 4px;
          }
          &:nth-child(2) {
            width: 50px;
            margin-bottom: 4px;
          }
          &:nth-child(3) {
            width: 80px;
            margin-bottom: 4px;
          }
        }
      }
      &__part-3 {
        display: flex;
        align-items: flex-end;

        .el-skeleton__p {
          width: 70px;
          margin-right: auto;
        }
        .el-skeleton__h1 {
          height: 35px;
          width: 80px;
        }
      }
    }
  }
</style>