<template>
  <li @click="select">
    <el-tag :class="'el-tag--blue transaction-type type-' + transferTypeTitle">
      {{ transferTypeTitle }}
    </el-tag>
    <div class="details">
      <p class="label">
        {{ transaction.title }}
      </p>
      <p class="value" v-if="transaction.status == 'pending'">
        {{ $t('RCD_pending') }}
      </p>
      <p class="value" v-else>
        {{ transaction.txnDate | date("MMM d, yyyy 'at' h:mm aa") }}
      </p>
    </div>
    <div
      :class="
        'amount ' +
          (transaction.status == 'pending'
            ? 'pending'
            : transaction.txnType === 'debit'
              ? 'minus'
              : 'plus')
      ">
      {{ transaction.amount | transactionAmount }}<br>
      <span class="font-12">{{ transaction.balance | currency }}</span>
    </div>
  </li>
</template>
<script>
import { mapMutations } from 'vuex';
import { transferType } from '../enums/index';
export default {
  name: 'TransactionComponent',
  props: {
    // eslint-disable-next-line
    transaction: Object
  },
  computed: {
    icon() {
      let iconName = '';
      /** @type {import('@m/transaction/store/index').transactionState['selectedTransaction']} */
      const transaction = this.transaction;
      if (transaction.transferType === 'card') {
        iconName = 'card';
        if (transaction.txnType == 'debit') {
          iconName += 'Debit';
        } else if (transaction.txnType == 'credit') {
          iconName += 'Credit';
        } else {
          iconName += 'Refund';
        }
      } else {
        iconName = 'trans';
        if (transaction.txnType == 'debit') {
          iconName += 'Debit';
        } else if (transaction.txnType == 'credit') {
          iconName += 'Credit';
        } else {
          iconName += 'Refund';
        }
      }
      return iconName;
    },
    transferTypeTitle() {
      return transferType['titles'][this.transaction.transferType];
    }
  },
  methods: {
    ...mapMutations('transaction', ['setSelectedTransaction']),
    select() {
      window.analytics && window.analytics.track('homeTransactionsDetails');
      this.setSelectedTransaction(this.transaction);
      this.$emit('showDetails');
    }
  }
};
</script>
